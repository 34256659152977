.demo {
  flex: 1;
  display: flex;
  position: relative;
}
.backgroundimage {
  display: block;
  height: 768px;
  width: 100%;
}

.insidedemo {
  position: absolute;
}

.titleimage {
  margin-top: 32px;
  margin-left: 117px;
  cursor: pointer;
  height:40px;
}

.date{
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  color: rgb(0, 0, 0, 0.38);
}

.date-time-pick{
  height: 42px;
  border-color: rgb(0, 0, 0, 0.38); 
}

.date-label {
  margin-top: 15px;
}

.quotedata {
  line-height: 1.25;
  font-size: 18px;
  color: #ffffff;
  font-style: italic;
  text-align: center;
  padding-left: 92px;
  padding-right: 92px;
  margin-top: 117px;
}

.statssection {
  width: 100%;
  display: flex;
  justify-content: "space-between";
  padding-left: 4px;
  padding-right: 4px;
  font-style: normal;
  padding-top: 108px;
}

.demoright {
  flex: 1;
  display: flex;
  padding-top: 20px;
  flex-direction: column;
}

.phoneemail {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 60px;
  color: rgb(0, 0, 0, 0.38);
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

.phone {
  height: 15px;
  width: 15px;
}

.formcontainer {
  padding-left: 111px;
}
.extra-gap {
  margin-top: 128px;
}
.extra-padding {
  margin-top: 30px;
}

.titledemo {
  font-size: 16px;
  margin-top: 30px;
  color: rgba(0, 0, 0, 0.54);
}

.demo-button-demo {
  height: 34px;
  border-style: solid;
  border-radius: 16px;
  margin-top: 10px;
  padding: 0px 22px 0px 22px;
  font-size: 13px;
  color: #ffffff;
  outline: none;
  cursor: pointer;
  border:none;
}

.react-phone-number-input__phone {
  border: 0;
  background-color: #fafafa;
}

.react-phone-number-input__row {
  width: 390px;
}

.react-phone-number-input__icon {
  height: initial;
  border: 0;
  margin-top: 5px;
}

.input {
  font-size: 15px;
  height: 42px;
  color: "#bcbcbc";
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 15px;
  padding-left: 21px;
  background-color: #fafafa;
  width: 413px;
  border: solid 0.5px #dbdbdb;
}

/* .line {
  padding-top: 5px;
} */

.input.input-error {
  border: solid 0.7px #ff0000;
}

.input-area {
  font-size: 15px;
  color: "#bcbcbc";
  height: 160px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 15px;
  padding-left: 21px;
  background-color: #fafafa;
  width: 413px;
  border: solid 0.5px #dbdbdb;
}

.input-select {
  padding-bottom: 10px;
  padding-top: 10px;
  width: 413px;
  height: 42px;
  margin-top: 20px;
  background-color: #fafafa;
  border: solid 0.5px #dbdbdb;
  padding-left: 10px;
}

.successcontainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.successinline {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.successdata {
  margin-left: auto;
  margin-right: auto;
}

.radio-group {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  color: rgb(0, 0, 0, 0.54);
  margin-top: 20px;
}

@media only screen and ( max-width: 425px){
  .date {
    flex-direction: column;
  }
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .demo {
    display: none;
  }
  .formcontainer {
    padding-left: 30px;
  }
  .phoneemail {
    padding-right: 30px;
    flex-direction: column;
    margin-left: auto;
  }
  .react-phone-number-input__row {
    width: 99%;
  }
  .input {
    width: 80%;
  }
  .date-time-pick{
    width: 80%;
  }
  .input-select {
    width: 80%;
  }
  .radio-group {
    flex-direction: column;
  }
  .input-area {
    width: 80%;
  }
  .successcontainer {
    padding-top: 50px;
  }
  .extra-gap {
    margin-top: 50px;
  }
}
