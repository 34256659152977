:root{
  --primary : #F7676F;
  --secondary : #F7676F;
}
.container {
  margin-left:auto;
  margin-right:auto;
  max-width: 1024px;
  clear: both;
}
.bold{
  font-weight: bold;
}
.section-content{
  padding:32px 0px;    
}
.section-content ul {
  display:flex;
  list-style:none;
}
.section-content ul li{
  width:100%;
}
.sun ul{
  margin:4px;
  padding:4px;
}
.center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button:focus {outline:0;}
  
  .button {
    width: 57px;
    height: 41px;
    border: 0px solid;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    float: right;
    outline:none;
    cursor: pointer;
  }
  .mpcard{
    padding-top:20px;
    padding-bottom:20px;
    min-height:300px;
  }
  .mpcardContent{
    clear:both;
    display: flex; 
    flex-wrap: wrap; 
    align-items: center;
    margin:40px auto;
    max-width:1680;
  }  
  .mpcenter{
    display: flex;
    flex-direction: column;
    align-items: center;  
  }
  h2{
    font-weight: bold;
    font-size:18px;
  }
  
  .body {
    padding-top: 101px;
    padding-left: 211px;
    display: flex;
    flex-direction: row;
  }
  
  .body-info {
    padding-left: 110px;
  }
  
  .desc-list {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.65);
  }
  
  .demo-button {
    height: 34px;
    border-style:solid;
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
    border-color: var(--primary);
    padding: 0px 20px 0px 20px;
    font-size: 13px;
    color: var(--primary);
    background-color: transparent;
    outline:none;
    cursor: pointer;
  }

  .see-video{
    display: none;
    margin-top: 23px;
    margin-left: 10px;
    font-size: 13px;
    text-decoration: underline;
    color: var(--primary);
  }

  .contact-button {
    height: 34px;
    border-style:solid;
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
    border-color: var(--primary);
    padding: 0px 22px 0px 22px;
    font-size: 13px;
    color: var(--primary);
    background-color: transparent;
    outline:none;
    cursor: pointer;
  }

  .filter {
    flex: 1;
    padding-top: 80;
  }
  
  .header {
    z-index: 10;
    /* position: relative; */
    /* flex-direction: column; */

    display: flex;
    flex-direction: row;
    position: fixed;
    width: 100%;
    top: 0;
    height: 73px;
    align-items: center;
    justify-content: space-around;
    background-color:#ffffff;
  }
  
  .image-container {
    width: 70px;
    height: 70px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .images {
    display: flex;
    flex-direction: row;
  }
  
  .line {
    margin-top: 25px;
    border-color: rgba(0, 0, 0, 0.1);
  }
  
  .list-item {
    height: 165px;
    width: 165px;
    box-shadow: 0 0 7px 0 rgba(210, 210, 210, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .nav ul {
    list-style: none;
  }
  
  .nav {
    height: 51px;
    margin-top: 73px;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.15);
  }
  
  .nav a {
    text-decoration: none;
    color: var(--secondary);
    font-size: 12px;
    font-weight:bold;
    text-transform: uppercase;
    padding: 8px 0px;
  }
  
  .nav ul {
    display: flex;
    flex-direction: row;
    align-content: center;
    padding: 0;
    padding-left: 30px;
  }
  
  .overlay {
    width: 70px;
    height: 70px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rebeccapurple;
    z-index: 2;
  }
  
  .product-title {
    color: #203047;
    font-size: 18px;
    font-weight: 700;
  }
  
  .product-price {
    color: #203047;
    font-size: 16px;
    font-weight: 700;
  }
  
  .product-subtitle {
    color: var(--secondary);
    font-size: 14px;
    font-weight: 600;
    padding-top: 5px;
  }
  
  .product-description {
    color: rgba(0, 0, 0, 0.87);
    font-size: 13px;
    width: 390px;
    padding-top: 20px;
  }
  
  .search {
    width: 450px;
    height: 41px;
    border-radius: 20px;
    margin-top:14px;
  }

  .search:hover{
    box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28);
  }
  
  .search-input {
    background-color: #F4F4F4;
    width: 393px;
    height: 41px;
    border: 1px solid #d8d8d8;
    box-sizing: border-box;
    padding-left: 13px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-right: none;
    font-size: 12px;
    outline:none;
  }
  
  .search-input::placeholder {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
  }
  
  .specification {
    margin-top: 94px;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: row;
  }
  
  .specification-technical {
    padding-top: 55px;
    padding-left: 214px;
  }
  
  .white-icon {
    color: #ffffff;
  }
  
  .search-container{
    padding-top: 20px;
    padding-left: 29px;
    padding-right: 29px;
  }

  .home-image{
    width: 100%;
  }

  .home-boxes{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 90px;
  }

  .home-individual-box{
    height: 426px;
    width: 500px;
    position: relative;
  }

  .space{
    margin-left: 28px;
  }

  .home-contact{
      padding-top: 79px;
      display: flex;
      flex-direction: row;
  }

  .space-contact{
    margin-left: 200px;
  }

  .home-company-numbers{
    display: flex;
    flex-direction:row;
    align-content: center;
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .cluster{
    display: flex;
    flex-direction: row;
    padding-top: 151px;
    justify-content: center;
  }

  .become-member{
    font-weight: bold;
    font-size: 18px;
    color: var(--secondary);
    padding-left: 81px;
    padding-top: 13px;
  }

  .become-member-description{
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: normal;
    width:400px;
  }
  .home-section1{
    position: absolute;
    top: 0;
    height: 567px;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-left: 10%;
    box-sizing: border-box;
  }

  .home-section1-inline{
    flex: 1;
    padding-right: 66px;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .feature-lines{
    display: flex;
    flex-wrap: wrap;
  }
  .more {
    padding-top: 29px;
    margin-top: 230px;
    display: flex;
    justify-content: center;
    position: relative;
  }
  .moreBundle {
    padding-top: 29px;
    margin-top: 120px;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .featured-products{
    height: 240px;
    margin-bottom:240px;
    background-color: var(--primary);
    padding-top: 40px;
    padding-left: 113px;
    padding-left: 56px;
    padding-right: 40px;
    }
  .non-featured{
    margin:0px 22px; 
  }
  .product-card{
    transition: transform .6s;
    margin-left: 29px;
  }
  .product-card:hover {
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1); 
  }
  .card {
    flex: 1;
    background-color: white;
    margin-top: 67px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 7px 0 rgba(210, 210, 210, 0.3);
    cursor: pointer;
  }
  .card img{
    width:165px;
    height: 165px;
  }

  .call-logo{
    position: fixed;
    bottom: 20px;
    z-index: 9;
    right: 20px;
    cursor: pointer;
  }

  .call-logo-tool-tip{
    position: fixed;
    bottom: 20px;
    z-index: 9;
    right: 90px;
    width: 186px;
    height: 72px;
    border-radius: 5px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.4);
    background-color: #ffffff;
  }

  .ctas{
    display: flex;
    flex-direction: row;
  }

  .why-choose-us-content{
    display: flex;
    flex-wrap: wrap;
    display: -webkit-flex; /* Safari */
    -webkit-flex-wrap: wrap; /*safari */
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .wcu-div{
    width: 300px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
  }
  .wcu-div-title{
    margin-top: 15px;
    font-size: 16px;
    font-weight: 600;
    color: #000000;
  }
  .wcu-div-desc{
    margin-top: 10px;
    line-height: 1.25;
    opacity: 0.87;
    font-size: 15px;
    color: #000000;
  }

  .numbers{
    min-width: 200px;
    padding-left: 50px;
    padding-right: 50px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  }

  .specialities{
    font-size: 18px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);
    margin-left: 4%;
    margin-right: 4%;
  }

  .specialities-title{
    margin-top: 76px; 
    margin-left: 7%;
    margin-right: 7%;
  }

  .carousel-arrow{
    width: 45px;
    height: 45px;
    box-shadow: 0 0 1px 0;
    background-color: #ffffff;
    margin-top: 109px;
    border-radius: 50%;
    border:none;
    cursor: pointer;
  }
  .arrows{
    font-size: 24px; 
    cursor:pointer; 
    padding:10px;
  }

  .specialities-card{
    /* width: 250px;
    height: 285px; */
    cursor: pointer;
    margin:2px;
  }

  .specialities-card-title{
    font-size: 16px;
    font-weight: 600;
    color: var(--secondary);
    padding-top: 8px;
    padding-left: 10px;
    text-align: center;
  }

  .carousel-container{
    width: 90%; 
    padding-left: 3%; 
    padding-right: 3%;
    max-height: 300px;
  }

  .product{
    display: flex; 
    flex-wrap: wrap;
    margin-left: 14%;
    margin-right: 14%;
    margin-bottom: 60px; 
  }

  .deals-header{
    height: 220px;
    opacity: 0.8;
    margin-top: 73px;
    background-image: linear-gradient(to left, var(--secondary), var(--primary));
    width:100%;
  }
  .thuthu{
    width:30%;
  } 

  .account-link {
    opacity: 0.8;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 23px;
    cursor: pointer;
  }

  .account-tab {
    width: 670px;
    background-color: #fff;
    margin-bottom: 50px;
  }

  .account {
    display: flex;
    flex-direction: row; 
    margin-left: 15%; 
    margin-right: 15%;
  }

  .account-links {
    display: flex;
    flex-direction: column;
    text-align: end;
  }

  .account-hrline {
    width: 1;
    height: 150px;
    opacity: 0.2;
    border: solid 1px #979797;
    margin-left: 20px; 
    margin-right:20px;
  }
  .cart-content{
    flex-direction:row;
  }  
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
  .price{
    padding:0px 32px;
  }
  .spacer{
    flex:1;
  }
  .cart-container{
    width:80%;
  }
  .price-content{
    min-width: 96px;
  }
  @media only screen and (max-width: 1024px){
    .wcu-div{
      width: 250px;
    }
    .blog-desc{
      margin-left: 15%;
      margin-right: 15%;
    }
  }
  @media only screen and (max-width: 768px) {
    .featured-products{
      height:auto;
      margin-bottom:0px;
      background-color: var(--primary);
    }    
    .account {
      flex-direction: column;
      margin-left: 3%;
      margin-right: 3%;
    }
    .account-hrline{
      display: none;
    }
    .account-tab {
      width: 100%;
    }
    .account-links {
      flex-direction: row;
    }
    .account-link {
      margin-right : 20px;
      margin-left: 20px;
    }
    .product{
      margin-left: 8%;
      margin-right: 8%;
    }
    .deals-header {
      margin-top: 0;
    }
    .carousel-arrow{
      width: 45px;
      height: 45px;
    }
    .carousel-container{
      width: 75%;
    }
    /* For mobile phones: */

    .why-choose-us-content{
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .filter {
      display: none;
    }
    .header{
      position: relative;
      flex-direction: column;
      height: 20%;
      margin-top: 5%;
      padding-bottom: 3%;
    }
    .search-input{
      width: 240px;
    }
    .nav {
      margin-top: 0px;
    }

    .see-video{
      display: block;
    }
    .search{
      margin-top: 3%;
      width: 100%;
    }

    .how{
      margin-right: auto;
      margin-left: 5%;
    }

    .contact-button{
      height: 40px;
      border-style:solid;
      border-top-left-radius: 1px;
      border-bottom-left-radius: 1px;
      border-top-right-radius: 1px;
      border-bottom-right-radius: 1px;
      width: 100%;
      position: fixed;
      bottom: 0px;
      z-index: 9;
      border-color: var(--primary);
      padding: 0px 22px 0px 22px;
      font-size: 13px;
      color: #ffffff;
      background-color: var(--primary);
      outline:none;
      cursor: pointer;
    }
    .search-container{
      padding-left: 0px;
      padding-right: 0px;
    }
    .blank{
      display: none;
    }
    .home-image{
      width: "100%";
      height: 567px;
    }
    .home-data{
      padding-left: 60px;
    }
    .home-boxes{
      flex-direction: column;
    }
    .home-individual-box{
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    .home-contact{
      flex-direction: column;
    }
    .space-contact{
      margin-top: 79px;
      margin-left: 0px;
    }
    .home-company-numbers{
      flex-direction:  column;
    }

    .cluster{
      flex-direction: column;
    }
    .cluster-image{
      margin-left: auto;
      margin-right: auto;
      width: 300px;
      height: 300px;
    }
    .become-member{
      margin-top: 20px;
      padding-left: 5%;
      margin-left: auto;
      margin-right: auto;
    }
    .become-member-description{
      width: 90%;
    }
    .how-it-works-desc{
      padding-left: 5%;
      width: 90%;
    }
    /* .feature-lines{
      flex-direction: column;
    } */
    .more{
      margin-top: 1100px;
    }
    .moreBundle{
      margin-top: 160px;
    }
    .wcu-div{
      margin-top: 30px;
    }
    .more-button {
      display: none;
    }
    .product-card{
      margin-left: 3%;
    }
    .card{
      margin-top: 27px;
    }
    .call-logo{
      bottom: 40px;
      right: 10px;
    }
  }

  @media only screen and (max-width: 736px){
    .featured{
      margin:"0px auto"
    }
    .non-featured{
      margin:0px auto
    }
    .thuthu{
      width:80%;
    } 
  }
@media only screen and (max-width:576px){
  .numbers{
    min-width: 200px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  }  
  .section-content ul{
    flex-direction:column;
  }
  .section-content{
    padding:2px 0px;  
  }
  .section-content ul li p{
    margin:0px;
  }
  .price{
    padding:0px 4px;
  }
  .cart-container{
    width:96%;
  }
  .price-content{
    min-width: 64px;
  }  
  .featured-products{
    height:auto;
    margin-bottom:0px;
    padding:20px 0px;
    background-color: var(--primary);
  }
    .non-featured{
      height:auto;
      margin:0px auto;
      padding:0px;
      background-color: var(--primary); 
    }
  .thuthu{
    width:80%;
  }
  .bannerCarousel{
    width:auto;
    /* height:272px; */
    object-fit:contain;
  }
  .bannerCarousel img{
    width:auto;
    /* height:272px; */
    object-fit:contain;
  } 
  .specialities-card{
    margin:auto;
  } 
  .card {
    width:150px;
    height: 150px;
    margin:12px 0px;
  }
  .card img{
    width:150px;
    height: 150px;
  }
  .product-card{
    width:160px;
    margin:4px auto;
    padding:4px;
  }
  .header {
    z-index: 0;
  }
}