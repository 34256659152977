  
  .hr {
    height: 1px;
    width: 100%;
    background-color:" #f7f7fa";
    border: 0;
    margin: 2rem 0;
  }
  
  
  .form {
    display: grid;
    grid-gap: 1rem;
  }
  
  .field {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #e3e5ed;
    padding: .5rem;
    border-radius: .25rem;
  }
  
  .field__label {
    color:  rgb(99, 96, 96);
    font-size: 0.6rem;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 0.25rem
  }
  
  .field__input {
    padding: 0;
    color:  rgb(99, 96, 96);
    margin: 0;
    border: 0;
    outline: 0;
    font-weight: bold;
    font-size: 1rem;
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
  }
  .field:focus-within {
    border-color: #000;
  }
  
  .fields {
    display: grid;
    grid-gap: 1rem;
  }
  
  .fieldbutton {
    text-transform: uppercase;
    font-size: 1rem;
    display: block;
    color: #fff;
    width: 100%;
    padding: 1rem;
    border-radius: 0.25rem;
    border: 0;
    cursor: pointer;
    outline: 0;
    bottom:5
  }
