/* @font-face {
  font-family: 'Nexa';
  font-display: swap;
  src: url("../../fonts/Nexa Light_1.otf") format("opentype");
}

@font-face {
  font-family: 'Nexa';
  font-display: swap;
  font-weight: bold;
  src: url("../../fonts/Nexa Bold_1.otf") format("opentype");
} */

.footer{
  background-color: #464646;
  padding-top: 53px;
  padding-left: 15%;
  padding-right: 15%;
  padding-bottom: 54px;
}

.footerTabs{
  display: flex;
  flex-direction: row;
}

.has-left{
  margin-left: 100px;
}

.textButton{
  font-size:12px;
  font-weight: 600;
  margin-right: 50px;
  padding-top: 10px;
  cursor: pointer;
}

@media only screen and (max-width : 1024px){
  .dropdown{
    position: relative;
    display: inline-block;
    color: rgba(0, 0, 0, 0.54);
    margin-left: 40%;
  }
  .dropdown-content{
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 200%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 2;
  }
  .dropdown:hover  .dropdown-content{
    display: block;
  }

  .arrow-down{
      width: 32px; 
      height: 32px; 
  }

  .right{
    width:8px;
    height:8px;
    float: right;
    border: solid rgba(0, 0, 0, 0.54);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    /* transform: rotate(-45deg); */
    -webkit-transform: rotate(-45deg);
  }
  .down {
    width:8px;
    height:8px;
    float: right;
    border: solid rgba(0, 0, 0, 0.54);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .up{
    width:8px;
    height:8px;    
    float: right;
    border: solid rgba(0, 0, 0, 0.54);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 2; /* Sit on top */
    padding-top: 0px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content */
  .modal-content {
    background-color: #f9f9f9;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 100%;
    z-index:100;
  }
  
  /* The Close Button */
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
}

@media only screen and (max-width : 768px){
    
    .footerTabs{
      display: flex;
      flex-direction:column;
    }
    .contact{
      display:flex;
      flex-direction: column;
    }

    .has-left{
      margin-left: 0px;
      margin-top: 40px;
      width: 80%;
    }

    .footer{
      padding-left: 70px;
    }

    .copyright{
      display: flex;
      flex-direction: column;
      height: 130px;
    }
    .dropdown{
      position: relative;
      display: inline-block;
      color: rgba(0, 0, 0, 0.54);
      margin-left: 40%;
    }
    .dropdown-content{
      display: none;
      position: absolute;
      background-color: #f9f9f9;
      min-width: 200%;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      padding: 12px 16px;
      z-index: 2;
    }
    .dropdown:hover  .dropdown-content{
      display: block;
    }

    .arrow-down{
      width: 32px; 
      height: 32px; 
    }

    .right{
      width:8px;
      height:8px;
      float: right;
      border: solid rgba(0, 0, 0, 0.54);
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      /* transform: rotate(-45deg); */
      -webkit-transform: rotate(-45deg);
    }
    .down {
      width:8px;
      height:8px;
      float: right;
      border: solid rgba(0, 0, 0, 0.54);
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
    .up{
      width:8px;
      height:8px;
      float: right;
      border: solid rgba(0, 0, 0, 0.54);
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }
    .modal {
      display: none; /* Hidden by default */
      position: fixed; /* Stay in place */
      z-index: 2; /* Sit on top */
      padding-top: 0px; /* Location of the box */
      left: 0;
      top: 0;
      width: 100%; /* Full width */
      height: 100%; /* Full height */
      overflow: auto; /* Enable scroll if needed */
      background-color: rgb(0,0,0); /* Fallback color */
      background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    }
    
    /* Modal Content */
    .modal-content {
      background-color: #f9f9f9;
      margin: auto;
      padding: 20px;
      border: 1px solid #888;
      width: 100%;
      z-index:100;
    }
    
    /* The Close Button */
    .close {
      color: #aaaaaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
    }
    
    .close:hover,
    .close:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }
}

@media only screen and (max-width : 736px){
  .dropdown{
    margin-left: 30%;
  }
}
@media only screen and (max-width:736px){

}
@media only screen and (min-width:766px){

}
@media only screen and (min-width:1024px){

}
@media only screen and (min-width:1440px){

}
@media only screen and (min-width:2440px){

}