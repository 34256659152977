.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container{
  margin-left:auto;
  margin-right:auto;
}

.body-product {

  display: flex;
  flex-direction: row;
}

.body-info-product {
  padding-left: 110px;
}

.desc-list {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.65);
}

.styles_modal__gNwvD{
  max-width: 500px;
}

.mod{
  width: 100%;
}

.image-container {
  width: 70px;
  height: 70px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
}

.images {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.line {
  margin-top: 25px;
  border-color: rgba(0, 0, 0, 0.1);
}

.list-item {
  height: 165px;
  width: 165px;
  box-shadow: 0 0 7px 0 rgba(210, 210, 210, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  width: 70px;
  height: 70px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rebeccapurple;
  z-index: 2;
}

.product-title {
  color: #203047;
  font-size: 18px;
  font-weight: 700;
}

.product-price {
  color: #203047;
  font-size: 16px;
  font-weight: 700;
}

.product-subtitle {
  font-size: 14px;
  font-weight: 600;
  padding-top: 5px;
}

.product-description {
  color: rgba(0, 0, 0, 0.87);
  font-size: 15px;
  width: 390px;
  padding-top: 20px;
  font-weight:500;
  /* white-space: pre-wrap; */
}

.search-input::placeholder {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
}

.specification {
  margin-top: 94px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: row;
}

.specification-technical {
  padding-top: 55px;
  padding-left: 214px;
}

.white-icon {
  color: #ffffff;
}

.specification-detail{
  display: flex; 
  flex-direction: row; 
  padding-top: 8px;
}

.left-specs{
  width: 40%;
}

.specification-detail-right{
  padding-left: 106px;
}

.technical-card{
  margin-left: 22.5%;
  margin-right: 22.5%;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 20px;
}

.technical-card-title{
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  margin-top: 40px;
}

.technical-card-desc{
  white-space: pre-wrap;  
  font-size: 14px;
  line-height: 1.5;
  text-align: justify;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 20px;
  width: 76.2%;
  margin-left: 11.9%;
  margin-right: 11.9%;
}

.image-tech{
  width: 100%;
  height: 46.6%;
}

@media only screen and (min-width: 769px) {
  .image-product{
    width:50%;
  }  
}

@media only screen and (max-width: 768px) {
  /* For Mobile Phones */
  .body-product{
    display: flex;
    flex-direction: column;
  }
  .body-info-product{
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .product-description{
    width: 90%;
  }
  
  .image-product{
    z-index:1;
  }

  .specification-technical{
    padding-left: 40px;
    padding-top: 30px;
    padding-right: 40px;
  }

  .left-specs{
    width: 80%;
  }

  .specification-detail{
    flex-direction: column;
  }

  .specification-detail-right{
    padding-left: 0px;
  }

  .technical-card{
    margin-left: 11%;
    margin-right: 11%;
  }

  .mod{
    width: 100%;
  }

}
